import { useAppDispatch, useAppSelector } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import MigrationMain from "./MigrationMain";
import MigrationVon from "./MigrationVon";
import MigrationUpload from "./MigrationUpload";
import MigrationLog from "./MigrationLog";
import MigrationComments from "./MigrationComments";
import { useHelpers } from "../../Utils/helpers";
import { useInternalActionCreator } from "../Actions/InternalActionCreator";
import { MigrationStatus, MigrationType, SourceType } from "./Enums/CommonEnums";
import { useUrlHelper } from "../Constants/urlHelper";
import { UserActionLog } from "./UserActionLog";
import { routes } from "../Constants/routes";
import { hasRole } from "../../Utils/Users";
import { UserRole } from "../Reducers/userReducer";
import { onToggleModal } from "../Reducers/modalReducer";
import { InternalFileUploadModal } from "./InternalFileUploadModal";
import { ImportFilesInternalModal } from "./ImportFilesInternalModal";
import { modalTypes } from "../Constants/modalTypes";
import { EditFileModal } from "./EditFileModal";
import { CsvImportConfirmation } from "./CsvImportConfirmation";
import { SendStatus } from "./Enums/LogType";
import { Helmet } from 'react-helmet';
import { MigrationStatusHistory } from "./MigrationStatusHistory";
import { useAdminActionCreator } from "../Actions/UseAdminActionCreator";

export default function InternalMigrationDetails() {
    const navigate = useNavigate()
    const { id } = useParams();
    const helpers = useHelpers();
    const ref = useRef(null);
    const urlHelper = useUrlHelper();

    // Local states
    const [showComponent, setShowComponent] = useState("");
    const [openStatus, setOpenStatus] = useState(false);
    const [csvOpen, setCsvOpen] = useState(false);
    const [filesOpen, setFilesOpen] = useState(false);
    const [sourceType, setSourceType] = useState<SourceType | undefined>(undefined);


    // App selectors
    let migration = useAppSelector((state) => state.internalMigration.internalMigration);
    let vonUsers = useAppSelector((state) => state.von.vonUsers);
    const currentUser = useAppSelector((state) => state.users.currentUser);
    const activeModal = useAppSelector((state) => state.modal.modal);

    // actions
    const migrationActions = useInternalActionCreator();
    const adminActions = useAdminActionCreator();
    const dispatch = useAppDispatch();

    useEffect(() => {
        id && migrationActions.getMigrationDetailsInternal(id)
        setShowComponent("main")
    }, []);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    function handleClickOutside(e: any) {
        const current = ref.current as Node | null
        if (current && !current.contains(e.target)) {
            setOpenStatus(false );
        }
    }

    function onExternalButtonClick(url:string) {
        window.open(url);
    }

    function onSetStatus(value : MigrationStatus){
        migrationActions.changeMigrationStatus(migration.id, value)
    }

    function onPreviousFailRestart(id : string) {
        migrationActions.restartFromPreviousFailStatus(id)
    }

    function onAssignToMe() {
        migrationActions.assignMigrationToSelf(migration.id);
    }

    function checkCollaboration() {
        migrationActions.checkCollaboration(migration.id);
    }

    const deleteMigration = () => {
        migrationActions.deleteMigration(migration.id);
    }

    const anonymizeMigration = () => {
        adminActions.anonymizeMigration(migration.id);
    }

    const importFiles = (sourceType: SourceType) => {
        setSourceType(sourceType);
        dispatch(onToggleModal(modalTypes.importCsvGuide));
    }

    function onUpdate() {

        if(!id)
            return;

        if(showComponent === "von")
            migration.customerNumber && migrationActions.getMigrationDetailsInternal(id, showComponent)
        else {
            migrationActions.getMigrationDetailsInternal(id, showComponent);
        }
    }

    if(!migration)
        return null;

    return <>
        <Helmet>
            <title>Visma Spcs Flytthjälp - {migration.customerName ?? ""} {migration.organizationNumber ?? ""}</title>
        </Helmet>
        <div className="pt-4 panel">
            <div className="d-flex justify-content-between mx-8 mt-8">
                <span>
                    <button onClick={() => navigate(routes.internalList)} className="btn"><i className="vismaicon vismaicon-sm vismaicon-undo"></i>Tillbaka</button>
                    <button className="btn" onClick={() => onUpdate()}><i className="vismaicon vismaicon-sm vismaicon-refresh"></i>Uppdatera</button>
                </span>
            </div>
            <div className="panel-heading">
                <h2>{migration.customerName}</h2>
            </div>
            <div className="row m-10">
                <div className="col-lg-6 col-sm-12">
                    <section className="panel bg-primary rounded-10 shadow-30 container-padding">
                        <h3 className="font-family-ubuntu-semibold">Status information</h3>
                        <div className="d-flex">
                            <div className="col-4">
                                <h4 className="text-primary font-weight-bold">Status </h4>
                                <p>{migration.statusName}</p>

                                {migration.eaConfiguration && migration.eaConfiguration.sendStatus !== SendStatus.None && <>
                                    <h4 className="text-primary font-weight-bold">Skickat status</h4>
                                    <p className="text-danger">{helpers.formatSendStatus(migration.eaConfiguration.sendStatus)}</p>
                                </>}

                                <h4 className="text-primary font-weight-bold">Senast ändrad </h4>
                                <p>{helpers.formatDateAndTime(migration.changed, true)}</p>
                                <h4 className="text-primary font-weight-bold">Tilldelad till </h4>
                                {migration.assignedTo ? <p>{migration.assignedTo.name}</p> : <button className="btn" onClick={() => onAssignToMe()}>Tilldela till mig</button>}
                            </div>
                            <div className="col-4">
                                <h4 className="text-primary font-weight-bold">Migreringstyp </h4>
                                <p>{MigrationType[migration.type]}</p>
                                <h4 className="text-primary font-weight-bold">Skapad </h4>
                                <p>{helpers.formatDateAndTime(migration.created, true)}</p>
                                <h4 className="text-primary font-weight-bold">Visma kundnummer </h4>
                                <p>{migration.customerNumber ? migration.customerNumber : 'N/A'}</p>
                            </div>
                            <div className="col-4">
                                <h4 className="text-primary font-weight-bold">Källa</h4>
                                <p>{helpers.getSourceType(migration.sourceType)}</p>
                                {migration.sourceType == SourceType.CsvFiles && migration.eaConfiguration.competitor && <>
                                    <h4 className="text-primary font-weight-bold">Programvaruleverantör</h4>
                                    <p>{migration.eaConfiguration.competitor.name}</p>
                                </>}

                                {/*<button className="btn" onClick={() => importCsv()}>Importera CSV</button>*/}
                                <div ref={ref} className={`internal-btn dropdown ${filesOpen ? "open" : ""}`}>
                                    <button className="btn min-w-100 dropdown-toggle" aria-haspopup="true"
                                        aria-expanded={filesOpen} onClick={() => setFilesOpen(!filesOpen)}>Importera filer</button>
                                    <ul className="dropdown-menu" aria-expanded={openStatus} aria-hidden={!openStatus}>
                                        <li onClick={() => importFiles(SourceType.CsvFiles)}><a>CSV</a></li>
                                        <li onClick={() => importFiles(SourceType.CompactFiles)}><a>Compact</a></li>
                                    </ul>
                                </div>

                                <div ref={ref} className={`internal-btn dropdown ${csvOpen ? "open" : ""}`}>
                                    <button className="btn min-w-100 dropdown-toggle" aria-haspopup="true"
                                            aria-expanded={csvOpen} onClick={() => setCsvOpen(!csvOpen)}>Skapa CSV-template</button>
                                    <ul className="dropdown-menu" aria-expanded={openStatus} aria-hidden={!openStatus}>
                                        <li onClick={() => migrationActions.downloadCsvTemplate("article", "Articles.csv")}><a>Articles.csv</a></li>
                                        <li onClick={() => migrationActions.downloadCsvTemplate("customer", "Customers.csv")}><a>Customers.csv</a></li>
                                        <li onClick={() => migrationActions.downloadCsvTemplate("supplier", "Suppliers.csv")}><a>Suppliers.csv</a></li>
                                    </ul>
                                </div>
                                <button className="btn" onClick={() => urlHelper.startService(migration.customerId)}>Öppna eEkonomi</button>

                            </div>
                       </div>
                    </section>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <section className="panel bg-primary rounded-10 shadow-30">
                        <div className="container-padding">
                            <h3 className="font-family-ubuntu-semibold">Funktion</h3>
                            <span className="">
                            <div className="internal-btn-grp">
                                {(hasRole(currentUser, UserRole.Admin) || migration.status === 1000) && <div className="internal-btn">
                                    <button className="btn" onClick={() => onPreviousFailRestart(migration.id)}>Starta
                                        om från
                                        föregående status
                                    </button>
                                </div>}
                                {migration.status === MigrationStatus.WaitingForCollaboration && <div><button className="btn" onClick={() => checkCollaboration()}>Kontrollera
                                    samarbete</button></div>}
                                <div className="internal-btn"><button className="btn" onClick={() => urlHelper.openCockpit(migration.customerNumber)}>Öppna i Skynet</button></div>
                                <div ref={ref} className={`internal-btn dropdown ${openStatus ? "open" : ""}`}>
                                    <button className="btn min-w-100 dropdown-toggle" aria-haspopup="true"
                                       aria-expanded={openStatus} onClick={() => setOpenStatus(!openStatus)}>Ändra status</button>
                                    <ul className="dropdown-menu" aria-expanded={openStatus} aria-hidden={!openStatus}>
                                        <li onClick={() => onSetStatus(MigrationStatus.FilesUploaded)}><a>Ladda upp filer</a></li>
                                        <li onClick={() => onSetStatus(MigrationStatus.ReadyForMigration)}><a>Starta om</a></li>
                                        <li onClick={() => onSetStatus(MigrationStatus.OnboardingCompleted)}><a>Slutför</a></li>
                                        <li onClick={deleteMigration}><a>Ta bort migrering</a></li>
                                        <li onClick={anonymizeMigration}><a>Anonymisera migrering</a></li>
                                    </ul>
                                </div>
                                <div className="internal-btn">
                                    <button className="btn" onClick={() => navigate(routes.additionalInfo(migration.id))}>Komplettering av data</button>
                                </div>
                            </div>
                            </span>
                        </div>
                    </section>
                </div>
            </div>
            <div>
                <div className=" panel rounded-10 container-padding">
                    <div className="d-flex justify-content-between mb-4">
                        <div className="btn-group btn-group-tabs ">
                            <button className={`btn ${showComponent === "main" ? "active" : ""}`} onClick={() => {
                                setShowComponent("main");
                            }}>Huvudinfo
                            </button>
                            <button className={`btn ${showComponent === "von" ? "active" : ""}`} onClick={() => {
                                setShowComponent("von");
                                {migrationActions.getVonUsersForMigration(migration.id)}
                            }}>Visma Online {vonUsers && vonUsers.length > 0 && <span className="badge badge-info ml-2">{vonUsers.length}</span>}
                            </button>
                            <button className={`btn ${showComponent === "upload" ? "active" : ""}`} onClick={() => {
                                setShowComponent("upload");
                            }}>Uppladdade filer {migration.files && migration.files.length > 0 && <span className="badge badge-info ml-2">{migration.files.length}</span>}
                            </button>
                            <button className={`btn ${showComponent === "comments" ? "active" : ""}`} onClick={() => {
                                setShowComponent("comments");
                            }}>Kommentarer {migration.comments && migration.comments.length > 0 && <span className="badge badge-info ml-2">{migration.comments.length}</span>}
                            </button>
                            <button className={`btn ${showComponent === "userAction" ? "active" : ""}`} onClick={() => {
                                setShowComponent("userAction");
                                migrationActions.getUserActionLog(migration.id);
                            }}>Användarlogg {migration.userActionLog && migration.userActionLog.length > 0 && <span className="badge badge-info ml-2">{migration.userActionLog.length}</span>}
                            </button>
                            <button className={`btn ${showComponent === "migrationStatusHistory" ? "active" : ""}`} onClick={() => {
                                setShowComponent("migrationStatusHistory");
                                migrationActions.getMigrationStatusHistory(migration.id);
                            }}>Statuslogg {migration.migrationStatusHistory && migration.migrationStatusHistory.length > 0 && <span className="badge badge-info ml-2">{migration.migrationStatusHistory.length}</span>}
                            </button>
                            <button className={`btn ${showComponent === "log" ? "active" : ""}`} onClick={() => {
                                setShowComponent("log");
                                migrationActions.getLogsForMigration(migration.id);
                            }}>Migreringslogg {migration.logs && migration.logs.length > 0 && <span className="badge badge-info ml-2">{migration.logs.length}</span>}
                            </button>
                        </div>
                    </div>
                    {showComponent === "main" && <MigrationMain migration={migration}/>}
                    {showComponent === "von" && <MigrationVon migration={migration}/>}
                    {showComponent === "upload" && <MigrationUpload migration={migration}/>}
                    {showComponent === "comments" && <MigrationComments migrationId={migration.id}/>}
                    {showComponent === "log" && <MigrationLog migration={migration}/>}
                    {showComponent === "userAction" && <UserActionLog migration={migration}/>}
                    {showComponent === "migrationStatusHistory" && <MigrationStatusHistory migration={migration}/>}
                </div>
            </div>
        </div>
        {activeModal === modalTypes.internalFileUploadModal && <InternalFileUploadModal migrationId={migration.id} />}
        {activeModal === modalTypes.importCsvGuide && <ImportFilesInternalModal migrationId={migration.id} files={migration.files} sourceType={sourceType} />}
        {activeModal === modalTypes.csvFileHandler &&  <CsvImportConfirmation />}
        {activeModal === modalTypes.editFile &&  <EditFileModal />}
    </>
}