import AOSummarizedStatus from "./AOSummarizedStatus";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import { useMigrationActionCreator } from "../Actions/MigrationActionCreator";
import AOSummarizedMigrationStatusList from "./AOSummarizedMigrationStatusList";
import SMBSummarizedStatus from "./SMB/SMBSummarizedStatus";
import YogaMan from "./Images/Pictogram-yoga-man.svg";
import { SmbMigration } from "../Reducers/userReducer";
import { onToggleModal } from "../Reducers/modalReducer";
import DeleteMigrationModal from "./DeleteMigrationModal";

export default function AOSummarizedListContainer() {

    const aoCustomers = useAppSelector(state => state.users.aoCustomers);
    const smbCustomers = useAppSelector(state => state.users.smbCustomers);
    const [noCompanies, setNoCompanies] = useState(false);
    const migrationActions = useMigrationActionCreator();
    const [modalMigrationIds, setModalMigrationIds] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const activeModal = useAppSelector((state) => state.modal.modal);
    useEffect(() => {
        migrationActions.getMigrationsForCurrentUser();
    }, [])

    useEffect(() => {
        if ((aoCustomers == null || aoCustomers.length === 0) && (smbCustomers == null || smbCustomers.length === 0))
            setNoCompanies(true);
        else
            setNoCompanies(false);
    }, [aoCustomers, smbCustomers])

    const handleModalToggle = (ids: string[]) => {
        setModalMigrationIds(ids);
        dispatch(onToggleModal("deleteMigrationsModal"));
    };
    
    if (!aoCustomers && !smbCustomers)
        return null;

    return (
        <>
            {noCompanies ? <div className="d-flex justify-content-center position-absolute align-items-center top-0 bottom-0 right-0 left-0">
                <div className="d-flex justify-content-center col-sm-3 flex-column">
                    <img src={YogaMan} alt="Man in yoga position" />
                    <h3 className="text-center bold">Hoppsan här var det tomt!</h3>
                    <div className="text-center">Här kommer du få en översikt på status på din dataflytt.</div>
                </div>
            </div> : <div className="alert alert-info d-flex justify-content-between align-items-center" role="alert" style={{ paddingTop: "20px" }}>
                Uppdatera denna sidan med hjälp av uppdateraknappen för att se aktuell status på din flytt <button className="btn " onClick={() => migrationActions.getMigrationsForCurrentUser()}><i className="vismaicon vismaicon-sm vismaicon-refresh"></i>Uppdatera</button>

            </div>}
            {!noCompanies && aoCustomers.map((aoCustomer: any, index: number) =>
                <span key={index}>
                    {(aoCustomer.aoMigrationCount.new.length > 0 || aoCustomer.aoMigrationCount.collaboration > 0 || aoCustomer.aoMigrationCount.error > 0) && <div className="panel">
                        <div className="container-padding">
                            <div>
                                <h2 className="font-weight-bold">Att hantera (kräver åtgärd)</h2>
                                <h3 className="font-weight-bold">{aoCustomer.customerName} {aoCustomer.organizationNumber} </h3>
                                {aoCustomer.aoMigrationCount.new.length > 0 && (
                                    <AOSummarizedMigrationStatusList
                                        migrations={aoCustomer.aoMigrationCount.new}
                                        customerId={aoCustomer.customerId}
                                        onModalToggle={handleModalToggle}
                                    />
                                )}
                                {aoCustomer.aoMigrationCount.collaboration > 0 && (
                                    <AOSummarizedStatus
                                        amount={aoCustomer.aoMigrationCount.collaboration}
                                        title={"klienter har blivit notifierade och behöver godkänna samarbete"}
                                        status={"collaboration"}
                                        customerId={aoCustomer.customerId}
                                    ></AOSummarizedStatus>
                                )}
                                {aoCustomer.aoMigrationCount.error > 0 && (
                                    <AOSummarizedStatus
                                        amount={aoCustomer.aoMigrationCount.error}
                                        title={"hanteras just nu av Visma Spcs"}
                                        status={"error"}
                                        customerId={aoCustomer.customerId}
                                    ></AOSummarizedStatus>
                                )}
                            </div>
                        </div>
                    </div>}
                    {aoCustomer.aoMigrationCount.ongoing > 0 && <div className="panel">
                        <div className="container-padding">
                            <div>
                                <h2 className="font-weight-bold">Pågående</h2>
                                <h3 className="font-weight-bold">{aoCustomer.customerName} {aoCustomer.organizationNumber} </h3>
                                <AOSummarizedStatus amount={aoCustomer.aoMigrationCount.ongoing} title={"klientöverföringar hanteras just nu av Visma Spcs"}
                                    status={"ongoing"} customerId={aoCustomer.customerId}></AOSummarizedStatus>
                            </div>
                        </div>
                    </div>}
                    {aoCustomer.aoMigrationCount.success > 0 && <div className="panel">
                        <div className="container-padding">
                            <div>
                                <h2 className="font-weight-bold">Slutförda klientöverföringar</h2>
                                <h3 className="font-weight-bold">{aoCustomer.customerName} {aoCustomer.organizationNumber} </h3>
                                <AOSummarizedStatus amount={aoCustomer.aoMigrationCount.success} title={"klienter överförda"}
                                    status={"success"} customerId={aoCustomer.customerId}></AOSummarizedStatus>
                            </div>
                        </div>
                    </div>
                    }
                </span>
            )}

            {smbCustomers && smbCustomers.map((smbCustomer: SmbMigration, index: number) =>
                <div key={index}>
                    <SMBSummarizedStatus migration={smbCustomer} onModalToggle={handleModalToggle} />
                </div>
            )}

            {activeModal === "deleteMigrationsModal" &&
                <DeleteMigrationModal migrationId={modalMigrationIds} />
            }
        </>
    );
}