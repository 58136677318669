import { useHttp } from "../../Utils/httpUtils";
import { useAppDispatch } from "../hooks";
import { storeBankList } from "../Reducers/bankListReducer";
import {
    Company,
    setPageNumber,
    StartGuideListModel,
    StartGuideObject,
    storeStartGuideData
} from "../Reducers/startguideReducer";
import { storeMigrationCount } from "../Reducers/aoReducer";
import { useHelpers } from "../../Utils/helpers";
import * as formValidationRules from "../Constants/formValidationRules";
import { showAlert } from "../Reducers/alertReducer";
import { onToggleModal } from "../Reducers/modalReducer";
import { routes } from "../Constants/routes";
import { useNavigate } from "react-router-dom";
import { AoMigration, storeCustomers } from "../Reducers/userReducer";
import {
    Migration,
    MigrationAdditionalInfo,
    storeAoUsers,
    storeMigrationDetails,
    storeMigrationForAdditionalInfo,
    storeMigrations
} from "../Reducers/migrationReducer";

import {
    Competitor,
    CompetitorMigration,
    storeCompetitors,
    storeCsvMigration
} from "../Reducers/manualMigrationReducer";

import { storeProductInformationList } from "../Reducers/productInformationReducer";
import {
    CollaborationStatus,
    EaccountingVariant,
    InvoicePeriod,
    InvoiceReceiver,
    SourceType,
} from "../Components/Enums/CommonEnums";
import { UserModel } from "../Reducers/vonReducer";
import { Product } from "../Reducers/smbStartGuidReducer";

export interface StartGuideData {
    id: string;
    company: Company;
    product: Product | null;
}

export interface ErrorList {
    id: string,
    errors: Error[]
}

export interface Error {
    field: string,
    message: string
}

export interface CollaborationsData {
    Id: string,
    EaccountingVariant: EaccountingVariant,
    ConsultantEmail: string,
    Period: InvoicePeriod,
    InvoiceReceiver: InvoiceReceiver,
    PrimaryContact: PrimaryContact | null,
    InvoicingInformation: InvoicingInformation | null
}

export interface PrimaryContact {
    firstName: string,
    lastName: string,
    mobile: string,
    email: string
}

export interface InvoicingInformation {
    address1: string,
    city: string,
    postalCode: string
}


export const useMigrationActionCreator = () => {
    const dispatch = useAppDispatch();
    const { request } = useHttp();
    const helpers = useHelpers();
    const navigate = useNavigate();

    const getMigrationsForBusiness = (callback?: Function): void => {

        let uri = `/api/migration/getMigrationsForStartGuide`;

        request(uri, null, (data: StartGuideListModel) => {
            callback && callback();
            dispatch(storeStartGuideData(data));

            if(data.collaborationItems.length == 0)
                dispatch(setPageNumber(3));

        });
    };

    const getMigrationsForStatusList = (currentAo: AoMigration, status: string | undefined): void => {
        request(`/api/migration/getMigrations/${currentAo.customerId}/${status}`, null, (data: StartGuideObject[]) => {
            dispatch(storeMigrations(data));
            getAoUsers();
        });
    };

    const getSmbMigrationDetails = (sourceType: SourceType): void => {
        request(`/api/migration/getMigration/${sourceType}`, null, (data: Migration) => {
            dispatch(storeMigrationDetails(data));
        });
    };

    const deleteMigrations = (migrationIds: string[]) => {
        const httpRequest = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(migrationIds),
        };

        request(`/api/migration/delete`, httpRequest, () => {
            navigate(routes.summarizedList);
            window.location.reload();
        });
    }

    const getLogFile = (migration : any): void => {
        request(`/api/migration/getLogTextFile/${migration.id}`, null, (data: any) => {
            const blob = new Blob([data], { type: 'text/plain' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${migration.customerName} logs.txt`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    const searchBankByName = (bankName: string) => {
        request(`/api/migration/searchBankByName/${bankName}`, null, (data: any) => dispatch(storeBankList(data)))
    }

    const getAllBanks = () => {
        request(`/api/migration/getAllBanks/`, null, (data: any) => dispatch(storeBankList(data)))
    }

    const validateCollaborations = (collaborations: StartGuideObject[], validationRules:any) : ErrorList[] | null => {
        let validationResult: ErrorList[] = [];
        let validations: any[] = []

        collaborations.forEach((item: StartGuideObject) => {
            validations = []
            validations.push(...helpers.validateForm({
                data: item.eaConfiguration.collaborationOptions,
                validationRules: validationRules
            }));

            if (item.eaConfiguration.collaborationOptions.eaccountingVariant > EaccountingVariant.noninteractive && !item.collaborationConfig.inviteWillBeSent) {

                //validate primaryContact
                validations.push(...helpers.validateForm({
                    data: item.eaConfiguration.collaborationOptions.primaryContact,
                    validationRules: formValidationRules.collaborationPrimaryContact
                }));

                // validate invoicingInformation
                validations.push(...helpers.validateForm({
                    data: item.eaConfiguration.collaborationOptions.invoicingInformation,
                    validationRules: formValidationRules.collaborationInvoicingInfo
                }))
            }

            if (validations.length > 0) {
                validationResult.push({id: item.id, errors: validations});
            }
        });

        if (validationResult.length > 0) {
           return validationResult;
        }

        return null
    }

    const validateStartGuideData = (migrations: StartGuideObject[], validationRules: any) : ErrorList[] | null=> {
        let validationResult: ErrorList[] = [];
        let validations: any[] = []

        migrations.forEach((item: StartGuideObject) => {

            if (item.collaborationStatus === CollaborationStatus.clientInvited)
                return; // We will not validate if the client has been invited.

            validations.push(...helpers.validateForm({
                data: item.eaConfiguration.company,
                validationRules: validationRules
            }));

            if (validations.length > 0) {
                validationResult.push({id: item.id, errors: validations});
            }
        })

        if (validationResult.length > 0) {
            return validationResult;
        }

        return null
    }

    const saveCollaborationsData = (collaborations: StartGuideObject[]) => {
        const startGuideData: CollaborationsData[] = [];

        if(validateCollaborations(collaborations, formValidationRules.collaborationsForm))
            return;

        collaborations
            .forEach((item: StartGuideObject) => {

                const options = item.eaConfiguration.collaborationOptions;

                const data : CollaborationsData = {
                    ConsultantEmail: options.consultantEmail,
                    EaccountingVariant: options.eaccountingVariant,
                    Id: item.id,
                    InvoiceReceiver: options.invoiceReceiver,
                    Period: options.period,
                    PrimaryContact: null,
                    InvoicingInformation: null,
                };

                if(options.eaccountingVariant > EaccountingVariant.noninteractive && !item.collaborationConfig.inviteWillBeSent) {
                    data.PrimaryContact = options.primaryContact;
                    data.InvoicingInformation = options.invoicingInformation;
                }

                startGuideData.push(data);
            });

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(startGuideData)
        }

        request(`/api/migration/saveCollaborationsData`, httpRequest, () => {
            dispatch(onToggleModal(""))
            getMigrationsForBusiness();
        });
    }

    const getOrCreateManualMigration = (sourceType: SourceType) => {

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify({sourceType: sourceType})
        }

        // Thi will create a new migration for current signed in user and comapny
        request(`/api/manualmigration/CreateOrGetManualMigration`, httpRequest, (data: CompetitorMigration) => {
            dispatch(storeCsvMigration(data));
        });
    }

    const getCompetitors = () => {
        request(`/api/manualmigration/GetCompetitors`, null, (data: Competitor[]) => {
            dispatch(storeCompetitors(data));
        });
    }

    const startManualMigration = (migration: CompetitorMigration) => {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(migration)
        }

        request(`/api/manualmigration/StartMigration`,  httpRequest, (data: any) => {
            navigate(routes.importFilesConfirm);
        });
    }

    const saveStartGuideDataAo = (startGuideList: StartGuideObject[]) => {
        const startGuideData : StartGuideData[] = [];

        if(validateStartGuideData(startGuideList, formValidationRules.eAccountingForm))
            return;

        startGuideList.forEach((item: StartGuideObject) => {
            startGuideData.push({
                company: item.eaConfiguration.company,
                id: item.id,
                product: null
            })
        });

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(startGuideData)
        }

        request(`/api/migration/saveStartGuide`, httpRequest, () => {
            navigate(routes.summarizedList)
        })
    }

    const saveStartGuideDataSmb = (startGuideData: StartGuideData, callback:Function) => {

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(startGuideData)
        }

        request(`/api/migration/saveSmbStartGuide`, httpRequest, callback)
    }

    const getNumberOfMigrations = (id: string) => {
        request(`/api/migration/getMigrationCount/${id}`, null, (data: any) => dispatch(storeMigrationCount(data)));
    }

    const getAoUsers = () => {
        request(`/api/migration/getUsersOnAo`, null, (data: UserModel[]) => dispatch(storeAoUsers(data)));
    }

    const getMigrationsForCurrentUser = (): void => {
        request(`/api/migration/getMigrationsForCurrentUser`, null, (data: any) => dispatch(storeCustomers(data)))
    }

    const getProductInformationList = () => {
        request(`/api/migration/getProductInformationList`, null, (data: any) => dispatch(storeProductInformationList(data)));
    }

    // GetMigrationForAdditionalInformation
    const getMigrationForAdditionalInfo = (id: string) => {
        request(`/api/migration/getMigrationForAdditionalInformation/${id}`, null, (data: MigrationAdditionalInfo) => dispatch(storeMigrationForAdditionalInfo(data)));
    }

    const validateAdditionalInformation = (migration: MigrationAdditionalInfo) : boolean => {

        const missing = migration.additionalInformations.filter(item => item.required && !item.value)

        if (missing.length > 0) {
            dispatch(showAlert({
                message: "Det finns obligatoriska fält som inte är ifyllda",
                title: "Tomma fält",
                style: "top"
            }))
            return false;
        }

        return true;
    }

    const saveAdditionalInformation = (migration: MigrationAdditionalInfo, callback?: Function) => {

        if (!validateAdditionalInformation(migration))
            return;

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(migration)
        }

        request(`/api/migration/saveAdditionalInformation/${migration.id}`, httpRequest, (json: any) => callback && callback());
    }

    return {
        getMigrationsForBusiness,
        getMigrationsForStatusList,
        searchBankByName,
        getAllBanks,
        saveStartGuideDataSmb,
        saveStartGuideDataAo,
        getNumberOfMigrations,
        validateStartGuideData,
        validateCollaborations,
        saveCollaborationsData,
        getAoUsers,
        getMigrationsForCurrentUser,
        getProductInformationList,
        getLogFile,
        getMigrationForAdditionalInfo,
        saveAdditionalInformation,
        getSmbMigrationDetails,
        getOrCreateManualMigration,
        startManualMigration,
        getCompetitors,
        deleteMigrations
    }
}