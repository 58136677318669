import { useAppDispatch } from "../hooks";
import { useHttp } from "../../Utils/httpUtils";
import { ExceptionRow, ReportRow, storeExceptions, storeReportData } from "../Reducers/adminReducer";
import { routes } from "../Constants/routes";
import { useNavigate } from "react-router-dom";

export const useAdminActionCreator = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {request} = useHttp();

    const getDoneMigrationsReport = () => {
        request(`/api/admin/getMigrationReportData`, null, (data: ReportRow[]) => dispatch(storeReportData(data)));
    };

    const getExceptions = () => {
        request(`/api/admin/getExceptions`, null, (data: ExceptionRow[]) => dispatch(storeExceptions(data)));
    };

    const sendToGdpr = () => {
        // This is not finished
        request(`/api/admin/SendToGdpr`, null, (data: any) => console.log(data));
    };

    const anonymizeMigration = (id: string) => {
        request(`/api/admin/anonymizeMigration/${id}`, null, (data: any) => {
            alert("Migration is sent to worker for anonymizing");
            navigate(routes.internalList);
        });
    }

    return {
        getDoneMigrationsReport,
        getExceptions,
        sendToGdpr,
        anonymizeMigration
    };
};