import {startGuideDropdowns} from "../app/Constants/dropdownLists";
import {MigrationCount} from "../app/Reducers/internalMigrationReducer";
import { EaccountingVariant, MigrationStatus, SourceType } from "../app/Components/Enums/CommonEnums";
import {Error} from "../app/Actions/MigrationActionCreator";
import { SendStatus } from "../app/Components/Enums/LogType";
import { getAutorizationLink } from "../app/VismaConnect";

export const useHelpers = () => {

    const authorizedCompanyName = () : string => {
        var authorizedUser = JSON.parse(localStorage["authorizedUser"]);
        return authorizedUser['http://vismaonline.com/claimtypes/customer_name'];
    };

    const changeAuthCompany = async () => {
        window.location.href = await getAutorizationLink();
    }

    const formatDateAndTime = (date: Date | null, time: boolean): string | null => {

        if (date == null)
            return null;

        date = new Date(date);

        const year =date.getUTCFullYear()

        // if date is 0001-01-01 00:00 return empty string
        if(year === 0)
            return null;


        let dd = (date.getDate()).toString();
        let mm = (date.getMonth() + 1).toString();
        const yyyy = (date.getFullYear()).toString();

        let formatted;

        if (parseInt(mm) < 10) {
            mm = 0 + mm;
        }

        if (parseInt(dd) < 10) {
            dd = 0 + dd;
        }
        formatted = yyyy + '-' + mm + '-' + dd + ' ';
        if (time) {
            let hh = (date.getHours()).toString();
            let MM = (date.getMinutes()).toString();
            if (parseInt(hh) < 10) {
                hh = 0 + hh;
            }

            if (parseInt(MM) < 10) {
                MM = 0 + MM;
            }
            formatted += hh + ':' + MM;
        }

        //if date is 2001-01-01 00:00 return empty string
        if (new Date("0001-01-01 00:00").getTime() === date.getTime())
            return null;

        return formatted;
    };

    function addErrMessage(errArr: Error[], errMessage: string, field: string) {
        errArr.push({field: field, message: errMessage});
    }

    const getStatusName = (status: MigrationStatus) => {
        if (status > MigrationStatus.FilesUploaded && status < MigrationStatus.Manual && status != MigrationStatus.ReuploadFiles)
            return "Ongoing";

        return MigrationStatus[status];
    };

    const geteAccountingVariant = (type: EaccountingVariant) => {
        switch (type) {
            case EaccountingVariant.noninteractive:
                return "Visma eEkonomi Start";
            case EaccountingVariant.accounting:
                return "Visma eEkonomi Bokföring";
            case EaccountingVariant.invoicingcollaboration:
                return "Visma eEkonomi Fakturering";
            case EaccountingVariant.standard:
                return "Visma eEkonomi Smart";
            case EaccountingVariant.pro:
                return "Visma eEkonomi Pro";
            case EaccountingVariant.invoicing:
                return "Visma eEkonomi Fakturering"
            default: 
                return "Saknas";
        }
    };

    const sourceTypeMapping: Record<SourceType, string> = {
        [SourceType.Admin]: "Visma Administration", // 1
        [SourceType.CsvFiles]: "Manuell filimport", // 3
        [SourceType.Lon]: "Visma Lön", // 4
        [SourceType.Fortnox]: "Fortnox", // 5
        [SourceType.CompactFiles]: "Visma Compact" // 6
    };

    const getSourceType = (type: SourceType) => sourceTypeMapping[type] || "Ej definierat";

    const getVatPeriod = (id: string) => {
        
        const res = startGuideDropdowns.vatPeriod.find(item => item.id === parseInt(id));
        if (res)
            return res.name;

        return "Saknas";
    };

    const validateForm = (state: any) : Error[] => {
        let errorMessagesArr: Error[] = []

        if (!state.validationRules)
            return errorMessagesArr;

        state.validationRules.map((fieldValidation: { inputId: string, message: string }) => {

            let fieldValue = state.data[fieldValidation.inputId] || '';

            if (fieldValue === 'undefined' || fieldValue === '' || (state.data[fieldValidation.inputId] instanceof Array && state.data[fieldValidation.inputId].length <= 0) || fieldValue === null) {
                addErrMessage(errorMessagesArr, fieldValidation.message, fieldValidation.inputId);
                return false;
            }

            applyRegExValidation(fieldValue, fieldValidation, errorMessagesArr);
            return true;
        });

        return errorMessagesArr;
    };

    function applyRegExValidation(value: any, validation: any, errArr: any) {
        if (validation.rule === 'required' || value === '') {
            return;
        }

        const re = new RegExp(validation.rule);

        const result = re.test(value.toString());

        if (!result) {
            addErrMessage(errArr, validation.message, validation.inputId);
        }
    }

    const getStatusForBadge = (status: string, migrationCount: MigrationCount[]): number => {
        switch (status) {
            case "new":
                return migrationCount.filter(m => m.status <= MigrationStatus.FilesUploaded).reduce((prev, curr) => prev + curr.count, 0);
            case "collaborations":
                return migrationCount.filter(m => m.status === MigrationStatus.WaitingForCollaboration).reduce((prev, curr) => prev + curr.count, 0);
            case "ongoing":
                return migrationCount.filter(m => m.status >= MigrationStatus.ReadyForMigration && m.status <= MigrationStatus.SaveDataToEAccounting).reduce((prev, curr) => prev + curr.count, 0);
            case "manual":
                return migrationCount.filter(m => m.status === MigrationStatus.Manual).reduce((prev, curr) => prev + curr.count, 0);
            case "success":
                return migrationCount.filter(m => m.status === MigrationStatus.Done).reduce((prev, curr) => prev + curr.count, 0);
            case "error":
                return migrationCount.filter(m => m.status === MigrationStatus.Error || m.status === MigrationStatus.ReuploadFiles).reduce((prev, curr) => prev + curr.count, 0);
            case "accept":
                return migrationCount.filter(m => m.status === MigrationStatus.AuthenticationFailed).reduce((prev, curr) => prev + curr.count, 0);
            case "complete":
                return migrationCount.filter(m => m.status >= MigrationStatus.OnboardingCompleted && m.status <= MigrationStatus.GdprCleanManual).reduce((prev, curr) => prev + curr.count, 0);
            default:
                return 0;
        }
    };

    const formatSendStatus = (status : SendStatus) => SendStatus[status]

    return {
        formatDateAndTime,
        validateForm,
        getStatusName,
        geteAccountingVariant,
        getVatPeriod,
        getStatusForBadge,
        getSourceType,
        formatSendStatus,
        authorizedCompanyName,
        changeAuthCompany
    };
};